import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
} from "@chakra-ui/react";
import bg from "./bg.png";
import globalImage from "./global.svg";
import saveImage from "./save.png"
import phoneImage from "./phone.png"
import icon1 from "./icon1.png"
import icon2 from "./icon2.png"
import inv from "./inv.webp"
import dots from "./dots.svg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import mailUs from "./mail.png"
import aboutUs from "./about.png"
import Toast from './toast';
import { useHistory } from 'react-router-dom';



export default function Landing() {
    const history = useHistory()
    const [reach, setReach] = useState("")
    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]}>
              <Text className="font" fontSize={["17px", "23px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    );

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" backgroundImage={bg} backgroundRepeat="no-repeat" backgroundSize={["cover", "100%"]}>

                <Flex w="100%" h="100%" bg="linear-gradient(0deg, #f5f5f5 0%, rgba(255,255,255,.75) 75%, rgba(255,255,255,1) 100%)" align="center" direction="column" px={["5", "32%"]} pt={["90px", "120px"]} pb={["180px", "180px"]}>
                    <Text lineHeight="1.2" textAlign="center" className="font" fontSize={["2.2rem", "2.986rem" ]}fontWeight="500" id="investArea" data-aos="fade-up" data-aos-duration="500">Leading business EquityFlex platform</Text>
                    <Text data-aos="fade-up" h="4px" bg="rgb(200,200,220)" my="5" w="40px" borderRadius="5px"></Text>
                    <Text color="rgb(150,150,170)" fontWeight="500" mb="8" data-aos="fade-up" textAlign="center" fontSize={["12px", "15px"]}>INVEST AND EARN PASSIVE INCOME STARTING TODAY!</Text>
                    
                    <Flex data-aos="fade-up" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} onClick={() => {
                        if(sessionStorage.getItem("eid") === null) {
                            Toast("Hello User, Log in or create an account with us to proceed", "success")
                            setTimeout(function() {
                                history.push("/login")
                            }, 1500)
                        }
                        else {
                            Toast("Alright, click the deposit now button to proceed with your investments process", "success")
                            setTimeout(function() {
                                history.push("/dashboard")
                            }, 1500)
                        }
                    }}><i className='mdi mdi-rocket' style={{ fontSize: "20px", marginRight: "5px" }}></i> INVEST NOW</Flex>
                </Flex>
            </Flex>
















            <Flex minH="80vh" w="100%" px={["5", "15%"]} py="70px" bgColor="#001e0e" color="#fff" backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right" direction="column">
                <Flex w="100%" justify="space-between" direction={["column", "row"]}>
                    <Flex w={["100%", "50%"]} mt="-150px" bg="#fff" borderRadius="5px" px={["4", "6"]} py="8" border="2px solid #001e0e" direction="column">
                        <Flex mb={["4", "5"]} borderRadius="8px" w="80px" h="80px" justify="center" align="center" border="2px solid #001e0e">
                            <Image src={saveImage} w="50px" />
                        </Flex>
                        <Text mb="2" fontWeight="600" color="#000" className="font" fontSize={["17px", "18px"]}>Equity Finance</Text>
                        <Text color="#494949" fontSize={["14px", "16px"]}>
                            The world of finance is constantly evolving, with new funding models being introduced to meet
                            the changing demands of businesses and investors alike. One of the most recent developments
                            in this space is the combination of private equity and Crowd Funding, which offers a unique blend
                            of traditional and modern financing methods. In this white paper, we will explore this innovative
                            funding model, along with the addition of a spot loan option, and its potential benefits for both
                            businesses and investors.
                            <Flex mt="8" display={["flex", "none"]}><Flex padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" color="#001e0e" bg="#fff" fontWeight="500" align="center" justify="center" fontSize="13px" border="2px solid #001e0e" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} onClick={() => {
                                const element = document.getElementById('learnArea')
                                return window.scrollTo({
                                top: element ? element.offsetTop - 50 : 0,
                                behavior: 'smooth',
                                })
                            }}>LEARN MORE <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex></Flex>
                        </Text>
                    </Flex>
                    <Flex direction="column" w={["100%", "40%"]} mt={["8", "0"]} display={["none", "flex"]}>
                        <Text fontWeight="500" fontSize="1.728rem" mb="2" className="font">Introduction</Text>
                        <Text h="4px" bg="rgb(230,230,240)" w="40px" borderRadius="5px"></Text>
                        <Flex mt="5"><Flex padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" color="#00b951" bg="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} onClick={() => {
                            const element = document.getElementById('learnArea')
                            return window.scrollTo({
                            top: element ? element.offsetTop - 50 : 0,
                            behavior: 'smooth',
                            })
                        }}>LEARN MORE <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex></Flex>
                    </Flex>
                </Flex>


                <Flex justify="space-between" w="100%" mt="100px" align="center" direction={["column", "row"]}>
                    <Flex w={["100%", "48%"]} data-aos="fade-up" data-aos-duration="700">
                        <Image src={phoneImage} w="100%" borderRadius="5px" />
                    </Flex>

                    <Flex w={["100%", "47%"]} direction="column" data-aos="fade-up" data-aos-duration="1000">
                        <Text fontWeight="500" mt={["8", "0"]} fontSize={["1.6rem", "1.728rem"]} mb="2" className="font">Private Equity and Crowd Funding</Text>
                        <Text h="4px" bg="rgb(230,230,240)" w="40px" borderRadius="5px"></Text>
                        <Text color="#fff" mt="3">
                            Private equity and Crowd Funding are two distinct financing models that have gained popularity in
                            recent years. Private equity involves investing in privately held companies, often with the goal of
                            acquiring a majority stake and working closely with management to improve the company's
                            performance. Crowd Funding, on the other hand, involves raising funds from a large number of
                            individuals, typically through an online platform and here at EquityFlex we are the best at what we do.
                        </Text>
                        <Flex mt="5"><Flex padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" color="#00b951" bg="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" fontSize={["13px", "15px"]} _hover={{ transform: "translate(0, -4px)" }} onClick={() => {
                            const element = document.getElementById('learnArea2')
                            return window.scrollTo({
                            top: element ? element.offsetTop - 50 : 0,
                            behavior: 'smooth',
                            })
                        }}>LEARN MORE <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex></Flex>
                    </Flex>
                </Flex>
            </Flex>
















            <Text id="learnArea2"></Text>
            <Flex py={["50px", "100px"]} w="100%" bg="rgb(250,250,250)" px={["5", "10%"]} direction={["column", "row"]} justify="space-between" overflowX="hidden">
                <Flex w={["100%", "48%"]} direction="column" data-aos="fade-left">
                    <Text fontWeight="600" lineHeight="1.15" fontSize={["1.5rem", "1.728rem"]} mb="2" className="font">Benefits of Private Equity and Crowd Funding Combination</Text>
                    <Text h="4px" bg="rgb(200,200,220)" mt="3" mb="10" w="40px" borderRadius="5px"></Text>

                    <Flex w="100%">
                        <Flex direction="column" mr="8">
                            <Text className="font" fontSize="18px" fontWeight="600">01.</Text>
                            <Text color="#494949" fontSize="18px" mt="1"><Text>.</Text><Text>.</Text><Text>.</Text><Text>.</Text></Text>
                        </Flex>
                        <Flex direction="column">
                            <Text className="font" fontSize="18px" fontWeight="600">Diversification of funding sources</Text>
                            <Text color="#494949" fontSize="14px" mt="1">
                                By tapping into both private equity and Crowd Funding,
                                businesses can access a wider pool of investors and reduce their reliance on any one source of
                                funding.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex w="100%" mt="5">
                        <Flex direction="column" mr="8">
                            <Text className="font" fontSize="18px" fontWeight="600">02.</Text>
                            <Text color="#494949" fontSize="18px" mt="1"><Text>.</Text><Text>.</Text><Text>.</Text><Text>.</Text></Text>
                        </Flex>
                        <Flex direction="column">
                            <Text className="font" fontSize="18px" fontWeight="600">Increased investor engagement</Text>
                            <Text color="#494949" fontSize="14px" mt="1">
                            Crowd Funding investors are often more engaged in the
                                success of the business, as they have a personal stake in its outcome. Private equity investors
                                can also provide valuable guidance and expertise.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex w="100%" mt="5">
                        <Flex direction="column" mr="8">
                            <Text className="font" fontSize="18px" fontWeight="600">03.</Text>
                            <Text color="#494949" fontSize="18px" mt="1"><Text>.</Text><Text>.</Text></Text>
                        </Flex>
                        <Flex direction="column">
                            <Text className="font" fontSize="18px" fontWeight="600">Control over ownership structure</Text>
                            <Text color="#494949" fontSize="14px" mt="1">
                                Private equity investors can acquire a significant stake in the
                                business, but the combination with Crowd Funding can help businesses retain some level of
                                control over ownership structure.
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>


                <Flex w={["100%", "45%"]} bg="rgb(235,235,240)" borderRadius="8px" py="12" px={["5", "12"]} mt={["50px", "125px"]} align="center" justify="center" direction="column" data-aos="fade-up">
                    <Image w="80px" src={icon1} mb="5" />
                    <Text className="font" fontSize="18px" fontWeight="600" textAlign="center">The Addition of a Spot Loan Option</Text>
                    <Text color="#494949" mt="4" textAlign="center">
                        Investors can now lend money for a specific proposal without committing to a long-term investment, thanks to the addition of the spot loan option to our private equity and Crowd Funding investments. Spot loans are short-term loans that can be used to finance a particular aspect of a project or fill a funding gap. This option is especially appealing to investors who desire a quick return on their investment without having to wait for the project to reach its full potential.
                    </Text>
                </Flex>
            </Flex>











                        
            <Text id="learnArea"></Text>
            <Flex py={["50px", "100px"]} w="100%" bgColor="#fff" px={["5", "10%"]} direction={["column", "row"]} justify="space-between" backgroundImage={dots} backgroundRepeat="no-repeat" backgroundSize="12%" backgroundPosition="top left" overflowX="hidden">
                <Flex w={["100%", "45%"]} mt={["0", "110px"]} backgroundImage={inv} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="8px" data-aos="fade-left">
                    <Flex w="100%" bg="rgba(0,0,0, 0.5)" py="12" px={["5", "12"]} align="center" justify="center" direction="column" color="#fff" borderRadius="8px">
                        <Image w="80px" src={icon2} mb="5" />
                        <Text className="font" fontSize="18px" fontWeight="600" textAlign="center">What is a Spot Loan?</Text>
                        <Text color="#fff" mt="5" textAlign="center">
                        A spot loan is a short-term loan that is typically used to cover expenses or bridge a funding gap.
                        Spot loans are typically unsecured, meaning that they do not require collateral, and they can be
                        approved and funded quickly.
                        </Text>
                    </Flex>
                </Flex>

                <Flex w={["100%", "48%"]} mt={["50px", "0"]} direction="column" data-aos="fade-up">
                    <Text fontWeight="600" lineHeight="1.15" fontSize={["1.5rem", "1.728rem"]} mb="2" className="font">Benefits of Adding a Spot Loan Option</Text>
                    <Text h="4px" bg="rgb(200,200,220)" mt="3" mb="10" w="40px" borderRadius="5px"></Text>

                    <Flex w="100%">
                        <Flex direction="column" mr="8">
                            <Text className="font" fontSize="18px" fontWeight="600">01.</Text>
                            <Text color="#494949" fontSize="18px" mt="1"><Text>.</Text><Text>.</Text><Text>.</Text><Text>.</Text></Text>
                        </Flex>
                        <Flex direction="column">
                            <Text className="font" fontSize="18px" fontWeight="600">Quick access to funds</Text>
                            <Text color="#494949" fontSize="14px" mt="1">
                                Spot loans can be approved and funded quickly, which can be beneficial
                                for investors that need immediate access to funds
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex w="100%" mt="5">
                        <Flex direction="column" mr="8">
                            <Text className="font" fontSize="18px" fontWeight="600">02.</Text>
                            <Text color="#494949" fontSize="18px" mt="1"><Text>.</Text><Text>.</Text><Text>.</Text><Text>.</Text></Text>
                        </Flex>
                        <Flex direction="column">
                            <Text className="font" fontSize="18px" fontWeight="600">Flexible repayment terms</Text>
                            <Text color="#494949" fontSize="14px" mt="1">
                                Spot loans typically have flexible repayment terms, which can help
                                investors manage their cash flow
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex w="100%" mt="5">
                        <Flex direction="column" mr="8">
                            <Text className="font" fontSize="18px" fontWeight="600">03.</Text>
                            <Text color="#494949" fontSize="18px" mt="1"><Text>.</Text><Text>.</Text></Text>
                        </Flex>
                        <Flex direction="column">
                            <Text className="font" fontSize="18px" fontWeight="600">Diversification of funding sources</Text>
                            <Text color="#494949" fontSize="14px" mt="1">
                                Adding a spot loan option can provide an additional source of
                                funding for investors, further diversifying their funding sources
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>

            </Flex>




            
            <Text id="faqArea"></Text>
            <Flex py={["50px", "100px"]} w="100%" bgColor="#001e0e" color="#fff" px={["5", "23%" ]}direction="column">
                <Text fontSize={["40px", "50px"]} mb="2" className="font" color="#00b951">FAQ</Text>
                <Text h="4px" bg="rgba(255,255,255,0.8)" mb="5" w="40px" borderRadius="5px"></Text>
                <Text mb={["2", "8"]} fontSize={["14px", "15px"]} color="rgba(255,255,255,0.8)">On EquityFlex Investment, Investment Conversion, and Withdrawal from EquityFlex and Private Equity</Text>

                <Accordion transition transitionTimeout={200}>
                    <AccordionItem header="What is EquityFlex investment?">
                        EquityFlex investment is the process of raising funds from a large number of people online to
                        finance a project or business venture. Investors can contribute small amounts of money in
                        exchange for equity, rewards, or interest on their investment.
                    </AccordionItem>

                    <AccordionItem header="How does investment conversion work?">
                        Investment conversion is the process of converting one type of investment into another. In the
                        context of EquityFlex, this often refers to converting convertible notes or SAFEs (Simple
                        Agreement for Future Equity) into equity shares in the company. This usually happens when the
                        company reaches a certain milestone or is acquired by another company.
                    </AccordionItem>

                    <AccordionItem header="How do I withdraw my investment from EquityFlex?">
                        Withdrawing your investment from EquityFlex varies depending on the platform and the type
                        of investment you made. Some platforms allow investors to sell their shares on a secondary
                        market, while others may have a lock-up period before you can sell your shares. It's important to
                        read the platform's terms and conditions before making an investment.
                    </AccordionItem>

                    <AccordionItem header="What is private equity?">
                        Private equity refers to investments made in private companies that are not publicly traded on a
                        stock exchange. Private equity firms typically invest larger amounts of money than EquityFlex
                        investors and may take a more active role in the management of the company.
                    </AccordionItem>

                    <AccordionItem header="How do I know if Crowd Funding or private equity is right for me?">
                        It depends on your investment goals and risk tolerance. Crowd Funding may be a good option if
                        you're looking to invest small amounts of money in startups or businesses you believe in.
                        Private equity may be a better option if you have a larger amount of capital and are willing to
                        take on more risk in exchange for potentially higher returns. It's important to do your research
                        and consult with a financial advisor before making any investment decisions.
                    </AccordionItem>
                </Accordion>
            </Flex>




            <Text id="aboutArea"></Text>
            <Flex py={["50px", "100px"]} w="100%" bgColor="#fff" px={["5", "10%"]} direction="column">
                <Flex w="100%">
                    <Flex w={["100%", "70%"]} pb="50px" direction="column" pr={["0", "5%"]} borderRight={["none", "1px solid rgb(200,200,220)"]} data-aos="fade-up">      
                        <Text fontWeight="600" lineHeight="1.15" fontSize={["1.6rem", "1.728rem" ]} mb="2" className="font">ABOUT US</Text>
                        <Text h="4px" bg="rgb(200,200,220)" mt="3" mb="10" w="40px" borderRadius="5px"></Text>
                        <Image w="80px" src={aboutUs} />
                        <Text mt="8" fontWeight="600" borderBottom="1px solid rgb(200,200,220)" pb="3">Who We Are: EquityFlex</Text>
                        <Text color="#494949" mt="3" ml={["5", "7"]}>
                            At EquityFlex, we are a private equity investment firm that offers innovative financing
                            options through EquityFlex and spot loans. We believe in supporting entrepreneurs and
                            businesses that are passionate about their vision and committed to making a positive impact in
                            their industries.
                        </Text>
                        <Text mt="8" fontWeight="600" pb="3" borderBottom="1px solid rgb(200,200,220)">Our EquityFlex Investment Platform</Text>
                        <Text color="#494949" mt="3" ml={["5", "7"]}>
                            Provides a unique opportunity for individuals and
                            organizations to invest in exciting new ventures. We work closely with our clients to create
                            compelling campaigns that attract investors and help bring their projects to life.
                            Spot Loans: We understand that sometimes businesses need access to capital quickly. That's
                            why we offer spot loans, which provide short-term financing to help cover unexpected expenses
                            or take advantage of new opportunities.
                        </Text>
                    </Flex>
                </Flex>
                <Text id="contactArea"></Text>


                <Flex w="100%">
                    <Flex w={["0%", "40%"]}></Flex>
                    <Flex w={["100%", "60%"]} direction="column" pl={["0", "5%"]} pt="50px" borderTop="1px solid rgb(200,200,220)" borderLeft={["none", "1px solid rgb(200,200,220)"]} align={["flex-start", "flex-end"]} data-aos="fade-up">    
                        <Text fontWeight="600" lineHeight="1.15" fontSize={["1.6rem", "1.728rem" ]} mb="2" className="font" textAlign={["left", "right"]}>CONTACT US</Text>
                        <Text h="4px" bg="rgb(200,200,220)" mt="3" mb="10" w="40px" borderRadius="5px"></Text>
                        <Image w="80px" src={mailUs} />
                        <Text mt="8" fontWeight="600" textAlign={["left", "right"]} fontSize={["15px", "18px"]} w="100%">Reach out to us via mail</Text>
                        <Text mb={["10", "10"]} mt="3" textAlign={["left", "right"]} fontSize={["13px", "15px"]}>
                            At EquityFlex, we are dedicated to providing customized financing solutions that meet the unique needs of each of our clients.
                        </Text>

                        <Flex align="center" w="100%" direction={["column", "row"]}>
                            <input style={{ width: "100%", padding: "17px 35px" }} placeholder="Enter a feedback" onInput={(e) => setReach(e.target.value)} />

                            <Flex w={["100%", "auto"]} mt={["2", "0"]} padding={["10px 30px", "15px 35px"]} cursor="pointer" ml={["0", "3"]} borderRadius="5px" bg="#00b951" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} onClick={() => {
                                if(reach !== "") {
                                    window.open("mailto: info@equityflex.investments?subject=Feedback From EquityFlex&body="+reach)
                                }
                                else {
                                    Toast("Enter your feedback", "error")
                                }
                            }}>SEND <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>




            
        </Flex>
    )
}