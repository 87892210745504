import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
    Spinner,
} from "@chakra-ui/react";
import { useLocation } from 'react-router-dom';


export default function AdShow() {
    const location = useLocation()
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        setTimeout(function() {
            setIsShow(true)
        }, 3000)
        setTimeout(function() {
            setIsShow(false)
        }, 6000)

        setInterval(function() {
            setIsShow(true)
            setTimeout(function() {
                setIsShow(false)
            }, 6000)
        }, 12000)
    }, [])

    const countries = ["Argentina","Aruba","Australia","Austria","Bahamas","Bahrain","Barbados","Belarus","Belgium","Belize","Bermuda","Brazil","British Virgin Islands","Brunei","Bulgaria","Cape Verde","Cayman Islands","Chad","Chile","China","Colombia","Costa Rica","Cote D Ivoire","Croatia","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Estonia","Ethiopia","Falkland Islands","Faroe Islands","Fiji","Finland","France","French Polynesia","French West Indies","Georgia","Germany","Gibraltar","Greece","Greenland","Grenada","Guam","Guatemala","Guernsey","Haiti","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Isle of Man","Israel","Italy","Jamaica","Japan","Jersey","Jordan","Kenya","Kuwait","Kyrgyz Republic","Laos","Latvia","Lebanon","Lesotho","Liechtenstein","Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova","Monaco","Montenegro","Montserrat","Morocco","Mozambique","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Norway","Oman","Panama","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Romania","Russia","Saint Pierre & Miquelon","San Marino","Saudi Arabia","Serbia","Seychelles","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka","St Kitts & Nevis","St Lucia","St Vincent","St. Lucia","Swaziland","Sweden","Switzerland","Taiwan","Thailand","Timor L'Este","Trinidad & Tobago","Tunisia","Turkey","Turks & Caicos","Ukraine","United Arab Emirates","United Kingdom", "United States", "Uruguay","Uzbekistan","Venezuela","Vietnam","Virgin Islands (US)"];

    const action = ["made a withdrawal of", "traded", "invested"]

    const price = [24560.00, 68799, 4568, 907, 53562, 87879, 9600, 57688, 36272, 789, 2566, 86960, 24356, 9079, 6748, 24435, 8595, 6748, 1324, 855, 8799, 224, 3647, 8669, 97970, 262637, 87969, 768746, 9594, 12626, 49948, 2415, 76896, 7464, 47585, 869, 9784]

    const isCountry = countries.sort(function () {
        return Math.random() - 0.5;
    })

    const isAction = action.sort(function () {
        return Math.random() - 0.5;
    })

    const isPrice = price.sort(function () {
        return Math.random() - 0.5;
    })


    return (
        <>
            {    
                location.pathname.includes('login') || location.pathname.includes('register') || location.pathname.includes('indi') ? <></> : isShow ?
                <Flex w={["80%", "auto"]} position="fixed" bottom="0" right="0" mb={["40%", "20%"]} mr={["2.5", "5%"]} data-aos="fade-left" bg="#fff" boxShadow="0px 5px 13px 0px rgba(0, 0, 0, .3)" padding="12px 27px" borderRadius="7px" zIndex="10">
                    <Text fontSize="14px">Someone from <Text as="span" fontWeight="600">{isCountry[0]}</Text> just <Text as="span" fontWeight="600">{isAction[0]}</Text> <Text as="span" fontWeight="600" color="#00b951">${isPrice[0].toLocaleString()}</Text></Text>
                </Flex>
                :
                null
            }
        </>
    )   
}