import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
    Avatar,
} from "@chakra-ui/react";
import logo from "./logo.png";
import { useLocation } from 'react-router-dom';



export default function Footer() {
    const location = useLocation()
    return location.pathname.includes('dashboard') || location.pathname.includes('indi') ? <></> : (
        <Flex pt="50px" pb="30px" w="100%" bgColor="#001e0e" color="#fff" px={["5", "10%" ]} direction="column">
            <Flex w="100%" align={["flex-start", "center" ]} justify="space-between" direction={["column", "row"]}>
                <Flex w={["100%", "30%"]} direction="column">  
                    <Flex flex="1" align="center">
                        <Image src={logo} h={["38px", "50px"]} className="wht" />
                        <Text h="3px" bg="#fff" ml={["4", "6" ]}w={["30px", "40px"]} borderRadius="5px"></Text>
                    </Flex>
                    <Text mt="8">We offer an extensive range of professional services and a high degree of specialization.</Text>
                </Flex>

                <Flex w={["100%", "50%"]} mt={["8", "0"]} align="center">
                    <Text w="100%">
                        <Text fontWeight="500" mb="1" color="#00b951" fontSize={["14px", "15px"]}>CONTACT</Text>

                        <Text mb="5" fontSize={["13px", "14px"]}>Stay connected with us</Text>

                        <Flex px="2" py="3" align="center" justify="center" border="1px solid rgba(255,255,255,0.2)" cursor="pointer" _hover={{ color: "#00b951" }} transition="200ms ease-in-out" onClick={() => window.open("mailto: info@equityflex.investments")}><i className='mdi mdi-email-outline' style={{ fontSize: "18px" }}></i> <Text ml="2">info@equityflex.investments</Text></Flex>
                    </Text>
                </Flex>
            </Flex>

            <Flex w="100%" mt={["35px", "50px"]} pt="30px" borderTop="1px solid rgba(255,255,255,0.2)" justify="space-between" direction={["column-reverse","row"]} pb={["2", "0"]}  fontSize={["14px", "15px"]}>
                <Text>&copy; 2023 EquityFlex Investment</Text>
                <Text mb={["10", "0"]}>By using this website you agree to the use of cookies in accordance with the cookies policy.</Text>
            </Flex>
        </Flex>
    )
}