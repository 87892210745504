import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
    Spinner,
} from "@chakra-ui/react";
import { ApiFile } from './request'
import Toast from './toast';


export default function Indi() {
    const [isAdmin, setIsAdmin] = useState(false)
    const [load1, setLoad1] = useState(false)
    const [load2, setLoad2] = useState(false)
    const [load3, setLoad3] = useState(false)
    const [load4, setLoad4] = useState(false)
    const [load5, setLoad5] = useState(false)
    const [load6, setLoad6] = useState(false)
    const [load7, setLoad7] = useState(false)
    const [load8, setLoad8] = useState(false)
    const [load9, setLoad9] = useState(false)
    const [load10, setLoad10] = useState(false)
    const [load11, setLoad11] = useState(false)

    /*useEffect(() => {
        const isAdminLogged = sessionStorage.getItem("isAdmin")
        if(isAdminLogged === true) {
            setIsAdmin(true)
        }
        else {
            setIsAdmin(false)
        }
    }, [])*/

    const inp1 = useRef("")
    const inp2 = useRef("")
    const inp3 = useRef("")
    const inp4 = useRef("")
    const inp5 = useRef("")
    const inp6 = useRef("")
    const inp7 = useRef("")
    const inp8 = useRef("")
    const inp9 = useRef("")
    const inp10 = useRef("")
    const inp11 = useRef("")
    const inp12 = useRef("")
    const inp13 = useRef("")
    const inp14 = useRef("")
    const inp15 = useRef("")
    const inp16 = useRef("")
    const inp17 = useRef("")
    const inp18 = useRef("")
    const inp19 = useRef("")
    const inp20 = useRef("")
    const inp21 = useRef("")
    const inp22 = useRef("")

    async function authAdmin(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("psc", inp1.current.value)
        setLoad1(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
            setIsAdmin(true)
        }
        else {
            Toast(res?.message, "error")
            setIsAdmin(false)
        }
        setLoad1(false)
    }

    async function loanFunc(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("addLoan", inp2.current.value)
        formData.append("amt", inp3.current.value)
        setLoad2(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad2(false)
    }

    async function loanUpd(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("updLoan", inp4.current.value)
        formData.append("status", inp5.current.value)
        setLoad3(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad3(false)
    }

    async function userBal(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("aBal", inp6.current.value)
        formData.append("amt", inp7.current.value)
        setLoad4(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad4(false)
    }

    async function remBal(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("rBal", inp8.current.value)
        formData.append("amt", inp9.current.value)
        setLoad5(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad5(false)
    }

    
    async function eBal(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("eBal", inp10.current.value)
        formData.append("amt", inp11.current.value)
        setLoad6(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad6(false)
    }

    async function reBal(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("reBal", inp12.current.value)
        formData.append("amt", inp13.current.value)
        setLoad7(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad7(false)
    }


    async function invU(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("invU", inp14.current.value)
        formData.append("invN", inp15.current.value)
        setLoad8(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad8(false)
    }

    async function invC(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("invC", inp16.current.value)
        formData.append("invN", inp17.current.value)
        setLoad9(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad9(false)
    }


    async function inP(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("inP", inp18.current.value)
        formData.append("inE", inp19.current.value)
        setLoad10(true)
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad10(false)
    }

    return (
        <Flex w="100%" minH="100vh" px={["5", "10%" ]} py="12" className='font' direction={["column", "row"]}>
            {
                isAdmin ?
                <>
                    <Flex w={["100%", "50%"]} borderRight={["none", "1px solid grey"]} pr={["0", "12"]} direction="column">
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">ADD LOAN TO CUSTOMER</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => loanFunc(e)}>
                            <input ref={inp2} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Email of user" required />
                            <input ref={inp3} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Amount" required />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load2 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>


                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">UPDATE LOAN STATUS</Text>
                        <Text mb="2">NOTE: 0 is for debt, 1 is for paid, 2 is for disbursed</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => loanUpd(e)}>
                            <input ref={inp4} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="refid of loan" required type="number" />
                            <input ref={inp5} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="status: 0, 1 or 2" required type="number" />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load3 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>

                        
                        
                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">NEW INVESTMENT NAME</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => invC(e)}>
                            <input ref={inp16} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="email of user" required />
                            <input ref={inp17} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="new name of investment" required />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load9 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>


                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">ADD TO INVESTMENT NAME</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => invU(e)}>
                            <input ref={inp14} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="email of user" required />
                            <input ref={inp15} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="new investment name to add to previous investments" required />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load8 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>


                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">CHANGE INVESTMENT PERCENTAGE</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => inP(e)}>
                            <input ref={inp18} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="email of user" required />
                            <input ref={inp19} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="%" required />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load10 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>                     
                    </Flex>










                    <Flex w={["100%", "50%"]} pl={["0", "12"]} mt={["12", "0"]} borderTop={["1px solid grey", "0"]} direction="column">
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">ADD USER BALANCE</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => userBal(e)}>
                            <input ref={inp6} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Email of user" required />
                            <input ref={inp7} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="amount to add" required type="number" />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load4 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>

                        
                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">REMOVE FROM USER BALANCE</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => remBal(e)}>
                            <input ref={inp8} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Email of user" required />
                            <input ref={inp9} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="amount to remove" required type="number" />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load5 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>


                         
                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">ADD TO PRIVATE EQUITY BALANCE</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => eBal(e)}>
                            <input ref={inp10} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Email of user" required />
                            <input ref={inp11} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="amount to add" required type="number" />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load6 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                        <Text w="100%" h="1px" bg="grey" mt="12"></Text>


                         
                        <Text mt="12"></Text>
                        <input type="text" style={{ width: "0", height: "0", visibility: "hidden" }} />
                        <Text mb="2" fontWeight="600">REMOVE FROM PRIVATE EQUITY BALANCE</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => reBal(e)}>
                            <input ref={inp12} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Email of user" required />
                            <input ref={inp13} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="amount to remove" required type="number" />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load7 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                    </Flex>
                </>
                :
                <>
                    <Flex w={["100%", "50%"]} direction="column">
                        <Text mb="2">ENTER PASSCODE</Text>
                        <form style={{ width: "100%" }} onSubmit={(e) => authAdmin(e)}>
                            <input ref={inp1} style={{ width: "100%", padding: "8px 20px", marginBottom: "10px" }} placeholder="Passcode" required type="password" />
                            <button style={{ background: "#00b951", padding: "8px 20px", color: "#fff" }} type="submit">{load1 ? <Spinner color="#fff" emptyColor="lightgrey" /> : "proceed"}</button>
                        </form>
                    </Flex>
                </>
            }
        </Flex>
    )
}