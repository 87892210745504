import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from 'axios';
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
  import ScrollToTop from "./sc";
  import Nav from './nav';
  import Landing from './landing';
  import Signin from './signin';
  import Footer from './footer';
  import Register from './register';
  import Dashboard from './dashboard';
  import Indi from './indi';
  import AdShow from './ad';
  import Reset from './rs';
  import Forgot from './fg';

function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])

	const [isLogIn, setIsLogIn] = useState(false)
    const [selected, setSelected] = useState("585858")

	useEffect(() => {
		if(sessionStorage.getItem("eid") !== null) {
			setIsLogIn(true)
		}
	}, [])

	return (
		<ChakraProvider>
			<Flex w="100%">
				<Router>				
					<ScrollToTop>
						<Flex w="100%" direction="column">
							<Nav isLogIn={isLogIn} setIsLogIn={setIsLogIn} selected={selected} setSelected={setSelected} />
							<Switch>
								<Route exact path="/"><Landing /></Route>
								<Route path="/login"><Signin setIsLogIn={setIsLogIn} setSelected={setSelected} /></Route>
								<Route path="/register"><Register setSelected={setSelected} /></Route>
								<Route path="/indi"><Indi /></Route>
								<Route path="/reset/:slug"><Reset /></Route>
								<Route path="/forgot"><Forgot /></Route>
								<Route path="/dashboard"><Dashboard /></Route>
								<Route><Landing /></Route>
							</Switch>
							<Footer />
							<AdShow />
						</Flex>
					</ScrollToTop>
				</Router>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
