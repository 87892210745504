import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
    Spinner,
    Box,
} from "@chakra-ui/react";
import icon1 from "./icon1.png"
import save3 from "./save3.png"
import private1 from "./private1.png"
import user1 from "./user1.png"
import { ApiFile } from './request'
import Toast from './toast';
import { Avatar } from '@chakra-ui/react';
import save2 from "./save2.png"
import TradingViewWidget from './td';
import cryp from "./cryp.png"
import btc from "./btc.png"
import usdt from "./usdt.png"


export default function Overview({ userData, loadUser, loansData, withdrawData, loadWithdraws }) {
    const [screen, setScreen] = useState(0)
    const [deposit, setDeposit] = useState(false)
    const menus = ["Overview", "Investments", "Spot Loan", "Deposit", "Withdraw", "My Account"]
    const [selected, setSelected] = useState(0)
    const [selectedFile, setSelectedFile] = useState(null)
    const [load1, setLoad1] = useState(false)
    const [load2, setLoad2] = useState(false)
    const [load3, setLoad3] = useState(false)
    const [oldPass, setOldPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [amt, setAmt] = useState("")
    const [bankname, setBankname] = useState("")
    const [routingNo, setRoutingNo] = useState("")
    const [bankacc, setBankacc] = useState("")
    const ref1 = useRef("")
    const ref2 = useRef("")

    const [send1, setSend1] = useState("")
    const [send2, setSend2] = useState("")

    function iniDeposit() {
            var a = ref1.current.value
            var b = ref2.current.value
            if(a !== "" && b !== "") {
                if(parseInt(b) < parseInt(500)) {
                    Toast("You cannot deposit less than $500", "error")
                }
                else {
                    setSend1(a)
                    setSend2(b)
                    setDeposit(true)
                }
            }
            else {
                Toast("Kindly choose the coin type and enter an amount", "error")
            }
    }

    async function uploadPhoto(e) {
        e.preventDefault()
        if(selectedFile !== null) {
            setLoad1(true)
            const formData = new FormData()
            formData.append("avatar", selectedFile)
            formData.append("uid", userData[0]?.uid)
            const res = await ApiFile("post", "upload.php", formData)
            if(res?.status) {
                await loadUser()
                setSelectedFile(null)
                Toast(res?.message, "success")
            }
            else {
                Toast(res?.message, "error")
            }
            setLoad1(false)
        }
        else {
            Toast("Click on select photo to upload a new profile photo", "error")
        }
    } 

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }

    async function changePass(e) {
        e.preventDefault()
        const formData = new FormData()
        formData.append("opass", oldPass)
        formData.append("npass", newPass)
        formData.append("uid", userData[0]?.uid)
        setLoad2(true)
        const res = await ApiFile("post", "upload.php", formData)
        if(res?.status) {
            await loadUser()
            Toast(res?.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoad2(false)
    }


    
    async function withdrawFunc(e) {
        e.preventDefault()
        if(parseInt(userData[0]?.bal) === 0) {
            Toast("Cannot make a wthdrawal request with insufficient funds!", "error")
        }
        else if(parseInt(amt) <= parseInt(userData[0]?.bal)) {
            const formData = new FormData()
            formData.append("uid3", userData[0]?.uid)
            formData.append("amt", amt)
            formData.append("bankname", bankname)
            formData.append("bankacc", bankacc)
            formData.append("rno", routingNo)
            setLoad3(true)
            const res = await ApiFile("post", "upload.php", formData)
            if(res?.status) {
                await loadUser()
                await loadWithdraws()
                document.getElementById('wa').value = ""
                document.getElementById('wb').value = ""
                document.getElementById('wc').value = ""
                document.getElementById('wr').value = ""
                Toast(res?.message, "success")
            }
            else {
                Toast(res?.message, "error")
            }
            setLoad3(false)
        }
        else {
            Toast("You don't have a sufficient balance to make this withdrawal request", "error")
        }
    }


    return (
        <Flex w="100%" align="flex-start" direction={["column", "row"]}>
            <Flex w={["100%", "20%" ]} px={["5", "4"]} bg="#fff" pt={["70px", "90px"]} direction={["row", "column"]} position="sticky" top="0" pb="4" left="0" zIndex="5" justify="space-between" boxShadow={["0 3px 5px -1px rgba(20, 20, 20, .08)", "none"]}>
                {
                    menus.map((item, index) => (
                        <Flex color={selected === index ? "#fff" : "#001e0e" } bg={selected === index ? "#001e0e" : "transparent"} w={["47px", "100%"]} pl={["0", "10"]} pr={["0", "5"]} justify={["center", "flex-start"]} align="center" key={index} cursor="pointer" h={["45px", "auto"]} py={["0", "3"]} mb={["0", "5"]} borderRadius={selected === index ? "12px" : "0"} _hover={{ bg: "#001e0e", color: "#fff", borderRadius: "12px" }} fontSize={["24px", '30px']} transition="200ms ease-in-out" onClick={() => {
                            setSelected(index)
                            setTimeout(function(){
                                if(index < 3) {
                                    setScreen(0)
                                    if(index === 0) {
                                        const element = document.getElementById('dashboard1')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop + 0 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                    else if(index === 1) {
                                        const element = document.getElementById('dashboard2')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop + 100 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                    else if(index === 2) {
                                        const element = document.getElementById('dashboard3')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop + 100 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                }
                                else if(index === 3) {
                                    setScreen(9)
                                    setTimeout(function(){
                                        window.scrollTo(0,0)
                                    }, 1)
                                }
                                else if(index === 4) {
                                    setScreen(1)
                                    setTimeout(function(){
                                        window.scrollTo(0,0)
                                    }, 1)
                                }
                                else {
                                    setScreen(2)
                                    setTimeout(function(){
                                        window.scrollTo(0,0)
                                    }, 1)
                                }
                            }, 100)
                        }}>
                            <i className={`mdi ${index === 0 ? "mdi-view-dashboard" : index === 1 ? "mdi-chart-bar" : index === 2 ? "mdi-bank" : index === 3 ? "mdi-database" : index === 4 ? "mdi-currency-usd" : "mdi-account-edit"}`}></i>
                            <Text fontWeight="500" ml="3" display={["none", "inline"]} fontSize="15px">{item}</Text>
                        </Flex>
                    ))
                }
            </Flex>





            <Flex w="100%" flex="1" px={["5", "7%"]} pt={["40px", "90px"]} bg="#fff" borderLeft="1px solid rgb(200,200,220)">
                <Flex w="100%" direction="column" data-aos="fade-left">
                    {
                        screen === 0 ?
                        <>
                            <Text id="dashboard1"></Text>
                            <Flex w="100%" justify="space-between" align="center">
                                <Text fontSize={["24px", "30px"]} fontWeight="500">Hi, <Text as="span" textTransform="capitalize">{userData[0]?.fname}</Text></Text>

                                <Flex fontSize={["13px", '14px']} border="1px solid #001e0e" as="button" padding={["8px 20px", "9px 30px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} onClick={() => {
                                    setScreen(9)
                                    setTimeout(function(){
                                        window.scrollTo(0,0)
                                    }, 1)
                                }}>Deposit Now <i className='mdi mdi-arrow-right' style={{ marginLeft: "8px" }}></i></Flex>
                            </Flex>
                            <Text mb="8" mt={["4", "0"]} color="#494949" fontSize={["13px", "15px"]}>Represented below are the currents details of your account and statistics</Text>

                            <Flex w="100%" justify="space-between" direction={["column", "row"]}>
                                <Flex w={["100%", "48%"]} borderRadius="12px" border="1px solid #001e0e" px={["5", "8"]} py={["8", "8"]} direction="column" justify="center">
                                    <Flex>
                                        <Flex border="1px solid #001e0e" p="6" borderRadius="100%">
                                            <Image src={icon1} w="80px" h="80px" />
                                        </Flex>
                                    </Flex>
                                    <Flex w="100%" justify="space-between" align="center">
                                        <Flex direction="column" mr="2">
                                            <Text mt="4" fontSize={["15px", "18px"]} fontWeight="500">Balance</Text>
                                            <Text fontSize={["12px", "14px"]}>Balance Remaining:</Text>
                                        </Flex>
                                        <Flex fontSize={["18px", "20px"]} fontWeight="600">${parseInt(userData[0]?.bal ?? 0)?.toLocaleString()+".00"}</Flex>
                                    </Flex>
                                </Flex>
                                
                                <Flex direction="column" w={["100%", "48%"]}>
                                    <Flex w="100%" mt={["4", "0"]} borderRadius="12px" border="1px solid #c27e02" px={["5", "8"]} py="5" justify="space-between" bg="rgba(252, 186, 66, 0.08)" align="center">
                                        <Flex w="150px" h="150px" border="10px solid #fcba42" borderRadius="100%" fontWeight="600" fontSize="18px" className="font" justify="center" align="center" bg="#fff">
                                            {"$"+parseInt(userData[0]?.spot ?? 0).toLocaleString()}
                                        </Flex>
                                        <Flex flex="1" ml={["4", '6']} direction="column">
                                            <Text fontWeight="600" fontSize={["12px", "15px"]}>Spot Loan Collected</Text>
                                            <Flex align={["flex-start", "center"]} mt="2" fontSize="13px" color="#494949" w="100%" direction={["column", "row"]}><Text mr="2">Total Due:</Text> ${(parseInt(userData[0]?.spot ?? 0) - parseInt(userData[0]?.spot_balance ?? 0))?.toLocaleString()+".00"}</Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex w="100%" borderRadius="12px" border="1px solid #04c971" px={["5", "8"]} mt="4" py={["6", "4"]} bg="rgba(4, 201, 113, 0.08)" justify="space-between" align="center">
                                        <Text>Total Loan Paid:</Text>
                                        <Text fontSize={["20px", "30px"]} fontWeight="bold">{"$"+parseInt(userData[0]?.spot_balance ?? 0).toLocaleString()}</Text>
                                    </Flex>
                                </Flex>
                                
                            </Flex>









                            
                            {userData[0]?.manager ? <>
                            <Text w="100%" h="1px" my="12" mb="12" bg="rgb(200,200,220)"></Text>
                            <Flex w="100%" direction="column">   
                                <Text fontSize={["18px", "30px"]} fontWeight="500">My Account Manager</Text>
                                <Text color="#494949" fontSize={["13px", "15px"]} mb={["6", "8"]}>Details of the manager in charge of your account</Text>
                                
                                <Flex w="100%" align={["flex-start", "center"]} direction={["column", "row"]}>
                                    <Flex align="center" flex="1">
                                        <Avatar size="md" bg="#001e0e" />
                                        <Text ml="3" fontWeight="bold" fontSize="18px">{userData[0]?.manager ?? ""}</Text>
                                    </Flex>

                                    <Flex w={["100%", "60%"]} direction="column" mt={["2", "0"]} pl={["59px", "0"]}>
                                        <Text fontWeight="500" mb="2" fontSize={["14px", "15px"]}>Manager Ratings</Text>
                                        <Flex w="100%">
                                            {
                                                ['', '', '', '', ''].map((item, index) => (
                                                    <Flex borderRadius="2px" fontSize="20px" color="#fff" bg="#e6d71c" w="28px" h="28px" justify="center" mr="3" align="center" key={index}>
                                                        <i className="mdi mdi-star"></i>
                                                    </Flex>
                                                ))
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex></> : <></>}

















                            <Text id="dashboard2"></Text>
                            <Text w="100%" h="1px" mt={["60px", "50px" ]}mb={["50px", "80px"]} bg="rgb(200,200,220)"></Text>
                            <Flex align="center" w="100%" mb="8" >
                                <Image src={private1} w="80px" mr="5" />
                                <Flex flex="1" direction="column">
                                    <Text fontSize={["18px", "30px"]} fontWeight="500">Private equity investment</Text>
                                    <Text color="#494949" mt="2px" fontSize={["13px", "15px"]}>Progress data based on your current private equity investment goal</Text>
                                </Flex>
                            </Flex>

                            <Flex pl="80px" ml="5" direction="column" w="100%" mb={["60px", "80px"]}>
                                <Text color="#494949" fontSize={["13px", "15px"]}>Current Company/Stock</Text>
                                <Text fontSize="20px"><i className='mdi mdi-arrow-down'></i></Text>
                                <Flex w="100%" pr={["5", "7%"]}><Flex fontWeight="bold" px={["8", "100px"]} py="2" border="1px solid #001e0e" align={["flex-start", "center"]} flexWrap="wrap" direction={["column", "row"]}>
                                    <i className='mdi mdi-chart-bar' style={{ fontSize: "20px" }}></i>
                                    <Text mt={["1", "0"]} ml={["0", "2"]}>{userData[0]?.investment !== "" && userData[0]?.investment !== undefined ? userData[0]?.investment : "None Available"}</Text>
                                </Flex></Flex>
                            </Flex>

                            <Flex w="100%">
                                <Flex bg="#4682B4" w={["40px", "120px"]} h="12px" borderRadius="8px 0 0px 8px" justify="flex-end">
                                    <Flex zIndex="1" bg="#4682B4" mr="-1" mt="-6.5px" h="25px" w="25px" borderRadius="100%" color="#fff" fontWeight="700" fontSize="10px" align="center" justify="center">1</Flex>
                                </Flex>
                                <Flex bg="#00b951" h="12px" w={userData[0]?.invpercent !== "" && userData[0]?.invpercent !== undefined && parseInt(userData[0]?.invpercent) > 25 ? parseInt(userData[0]?.invpercent) > 69 ? "100%" : userData[0]?.invpercent+"%" : ["25%", "22%"]} justify="flex-end">
                                    <Text ml="auto" mr="auto" fontSize="9px" color="#fff" fontWeight="700">{userData[0]?.invpercent !== "" && userData[0]?.invpercent !== undefined ? parseInt(userData[0]?.invpercent) : "0"}%</Text>
                                    <Flex zIndex="2" bg="#00b951" mr="-1" mt="-6.5px" h="25px" w="25px" borderRadius="100%" color="#fff" fontWeight="700" fontSize="10px" align="center" justify="center">2</Flex>
                                </Flex>
                                
                                <Flex w={["74px", "140px"]} bg="rgb(220,220,230)" h="12px"direction="column" position="relative">
                                    <Flex w={(userData[0]?.equity_percent !== "" && userData[0]?.equity_percent !== undefined) ? userData[0]?.equity_percent+"%" : "0%"} bg="orange" h="12px" justify="flex-end">
                                    </Flex>
                                    <Flex position="absolute" ml={["15px", "40px"]} zIndex="1" bg="orange" mr={["4", "8"]} mt="-6.5px" h="25px" w="25px" borderRadius="100%" color="#fff" fontWeight="700" fontSize="10px" align="center" justify="center">3</Flex>
                                </Flex>
                                <Flex minW={["44px", "61px"]} bg="rgb(220,220,230)" flex='1' h="12px" borderRadius="0px 8px 8px 0px" justify="flex-end">
                                    <Flex zIndex="1" bg="rgb(220,220,230)" mr={["4", "8"]} mt="-6.5px" h="25px" w="25px" borderRadius="100%" color="#fff" fontWeight="700" fontSize="10px" align="center" justify="center">4</Flex>
                                </Flex>
                            </Flex>

                            <Flex w="100%" mt="5" fontWeight="700" fontSize={["9px", "13px"]}>
                                <Text ml={["3", "4"]} w={["40px", "120px"]} color="#4682B4"><Flex justify="flex-end"><Flex>Registered</Flex></Flex></Text>
                                <Text mr="2" ml="auto" color="#00b951" w={userData[0]?.invpercent !== "" && userData[0]?.invpercent !== undefined && parseInt(userData[0]?.invpercent) > 25 ? parseInt(userData[0]?.invpercent) > 69 ? "100%" : userData[0]?.invpercent+"%" : ["25%", "22%"]}><Flex justify="flex-end"><Flex ml={["4", "4"]}>Total Invested - ${parseInt(userData[0]?.equity ?? 0)?.toLocaleString()+".00"}</Flex></Flex></Text>
                                <Flex ml="6" wordBreak="break-all" textAlign="right" color="rgb(220,220,230)" minW={["88px", "130px"]} flex="1"><Text ml="auto" mr="0" color="#494949">Goal Complete - ${parseInt(userData[0]?.goal_amount ?? 0)?.toLocaleString()+".00"}</Text></Flex>
                            </Flex>

                            <Flex align="center" mt="8" direction={["column", "row"]}>
                                <Text textAlign={["center", "left"]} fontSize="50px" color="rgb(200,200,210)" mr={["0", "3"]}><i className='mdi mdi-lock-reset'></i></Text>
                                <Text mt="0" fontWeight="700" textAlign={["center", "left"]}>Total Earnings<Text mt="1" fontSize={["13px", "14px"]} fontWeight="400">This will be unlocked after your goal is complete</Text></Text>
                                <Text mt={["5", "0"]} ml={["0", "12"]} fontWeight="700" textAlign={["center", "left"]} fontSize="24px">${parseInt(userData[0]?.equity_earning ?? 0)?.toLocaleString()+".00"}</Text>
                            </Flex>

                            
                            












                            <Text w="100%" h="1px" mt={["70px", "100px"]} mb="12" bg="rgb(200,200,220)"></Text>


                            <Flex w="100%" align="center">
                                <Image src={cryp} h="80px" mr="5" />
                                <Flex direction="column">        
                                    <Text fontSize={["24px", "30px"]} fontWeight="500">My Crypto Investments</Text>
                                    <Text color="#494949" mt="2px" fontSize={["13px", "15px"]}>investment information for your crypto investment plans</Text>
                                </Flex>
                            </Flex>
                            <Flex mt="8" w="100%" borderRadius="12px" border="1px solid #04c971" px={["5", "8"]} py={["6", "4"]} bg="rgba(4, 201, 113, 0.08)" justify="space-between" align="center">
                                <Text>Total Invested:</Text>
                                <Text fontSize={["20px", "25px"]} fontWeight="bold">{userData[0]?.crypto_invested ?? ""}</Text>
                            </Flex>
                            
                            <Flex mt="4" w="100%" borderRadius="12px" border="1px solid #edd311" px={["5", "8"]} py={["6", "4"]} bg="rgba(237, 211, 17, 0.08)" justify="space-between" align="center">
                                <Text>Available Profit:</Text>
                                <Text fontSize={["20px", "25px"]} fontWeight="bold">{userData[0]?.crypto_profit ?? ""}</Text>
                            </Flex>
                            
                            <Flex mt="4" w="100%" borderRadius="12px" border="1px solid #086fcf" px={["5", "8"]} py={["6", "4"]} bg="rgba(8, 111, 207, 0.08)" justify="space-between" align="center">
                                <Text>Investment Plan:</Text>
                                <Text fontSize={["18px", "20px"]} fontWeight="bold"><Text as="span" mr="2"><i className='mdi mdi-chart-bar'></i></Text>{userData[0]?.crypto_plan ?? "None"}</Text>
                            </Flex>

                            <Flex mt="4" w="100%" borderRadius="12px" border="1px solid #de04ba" px={["5", "8"]} py={["6", "4"]} bg="rgba(222, 4, 186, 0.08)" justify="space-between" align="center">
                                <Text>Plan Duration:</Text>
                                <Text fontSize={["16px", "20px"]} fontWeight="bold"><Text as="span" mr="2"><i className='mdi mdi-timer'></i></Text>{userData[0]?.crypto_duration ?? "None"}</Text>
                            </Flex>
                            <Text w="100%" mt="6" h={["360px", "400px"]}>
                                <TradingViewWidget />
                            </Text>
                            
                            






















                            <Text id="dashboard3"></Text>
                            <Text w="100%" h="1px" mt={["70px", "100px"]} mb="12" bg="rgb(200,200,220)"></Text>
                            <Flex w="100%" align="center">
                                <Flex borderRadius="100%" w="80px" h="80px" justify="center" align="center" border="2px solid #001e0e" mr="4">
                                    <Image src={save3} w="45px" />
                                </Flex>
                                <Flex direction="column">        
                                    <Text fontSize={["24px", "30px"]} fontWeight="500">Spot Loan</Text>
                                    <Text mt="1" fontWeight="500" fontSize={["16px", "18px"]}>Total Due (${(parseInt(userData[0]?.spot ?? 0) - parseInt(userData[0]?.spot_balance ?? 0))?.toLocaleString()+".00"})</Text>
                                </Flex>
                            </Flex>
                            <Text mb="35px" color="#494949" mt="5" fontSize={["13px", "15px"]}>Total spot loan history - {Array.isArray(loansData[0]) ? loansData[0].length+" loan(s) history available" : "No loan history at the momemnt"}</Text>

                            <Box bg="rgba(255,0,0,0.08)" w="100%" overflowX={["scroll", "hidden"]} borderRadius="10px" border="1px solid rgb(255,0,0)"><Flex w={["650px", "100%" ]}pb={["3", "8"]} pt={["7", "12"]} px="5" borderRadius="10px" direction="column">
                                <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="500" borderBottom="1px solid rgb(200,200,220)">
                                    <Flex w='10%'>S/N.</Flex>
                                    <Flex w='20%'>Amount</Flex>
                                    <Flex w='30%'>Date</Flex>
                                    <Flex w="20%">Ref ID</Flex>
                                    <Flex w="20%">Status</Flex>
                                </Flex>
                                {
                                    Array.isArray(loansData[0]) &&
                                    loansData[0]?.map((item, index) => (  
                                    <Flex align="center" w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="500" borderBottom="1px solid rgb(200,200,220)" key={index}>
                                        <Flex w='10%'>{index +1}.</Flex>
                                        <Flex w='20%'>{"$"+parseInt(item.amount)?.toLocaleString()+".00"}</Flex>
                                        <Flex w='30%'>{item.transdate}</Flex>
                                        <Flex w="20%"><Text borderBottom="1px solid #000" cursor="pointer" onClick={() => {
                                            navigator.clipboard.writeText(item.refid)
                                            Toast("Reference ID copied!", "success")
                                        }}>{item.refid}</Text></Flex>
                                        <Flex w="20%"><Flex borderRadius="5px" fontWeight="bold" px="4" py="1" color="#fff" bg={item.status === "0" ? "rgb(255,0,0)" : item.status === "1" ? "#00b951" : "#086dbf"}>{item.status === "0" ? "Debt" : item.status === "1" ? "Paid" : "Disbursed"}</Flex></Flex>
                                    </Flex>
                                    ))
                                }
                            </Flex></Box>
                            <Text pb={["100px", "200px"]}></Text>
                        </>
                        :
                        screen === 1 ?
                        <>
                            <Flex align="center"><Image src={save2} mr="5" w="60px" /> <Text fontSize={["24px", "30px" ]} fontWeight="500">Withdrawals</Text></Flex>

                            <Text w="100%" h="1px" mt={["4", "6"]} mb="6" bg="rgb(200,200,220)"></Text>

                            <Text fontSize="20px" fontWeight="500">Create a Withdrawal Request</Text>
                            <Text mb="8" color="#494949" mt="1" fontSize={["13px", "15px"]}>Balance Left: <Text as="span" fontWeight="bold" ml="1" mr="2">${parseInt(userData[0]?.bal).toLocaleString()}</Text> - Kindly fill in the details provided below</Text>
                            <Flex direction="column" w="100%">
                                <form style={{ width: "100%" }} onSubmit={(e) => withdrawFunc(e)}>
                                    <Flex w='100%' justify="space-between" flexWrap="wrap">
                                        <Text w={["48%", "24%"]}><input style={{ width: "100%", padding: "17px 35px", border: "2px solid #001e0e" }} id="wa" placeholder="Amount ($)" onInput={(e) => setAmt(e.target.value)} required /></Text>
                                        <Text w={["48%", "24%"]}><input id="wb" style={{ width: "100%", padding: "17px 35px", border: "2px solid #001e0e" }} placeholder="Bank Name" onInput={(e) => setBankname(e.target.value)} required /></Text>
                                        <Text mt={["4", "0"]} w={["100%", "24%"]}><input id="wc" style={{ width: "100%", padding: "17px 35px", border: "2px solid #001e0e" }} placeholder="Account Number" onInput={(e) => setBankacc(e.target.value)} required /></Text>
                                        <Text mt={["4", "0"]} w={["100%", "24%"]}><input id="wr" style={{ width: "100%", padding: "17px 35px", border: "2px solid #001e0e" }} placeholder="Account Routing" onInput={(e) => setRoutingNo(e.target.value)} required /></Text>
                                    </Flex>
                                    <Flex mt="4" justify="flex-end">
                                        <Flex w={["100%", "auto"]} padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none">
                                            {
                                                load3 ? <Spinner color="#fff" emptyColor="lightgrey" /> : 
                                                <Flex align="center">REQUEST <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                                            }
                                        </Flex>
                                    </Flex>
                                </form>
                            </Flex>

                            <Text w="100%" h="1px" mt={["8", "6"]} mb="6" bg="rgb(200,200,220)"></Text>
                            

                            <Text fontSize="20px" fontWeight="500">History</Text>
                            <Text mb="35px" color="#494949" mt="1" fontSize={["13px", "15px"]}>Total withdrawal history - {Array.isArray(withdrawData[0]) ? withdrawData[0].length+" withdrawal(s) has been processed" : "No withdrawal history at the momemnt"}</Text>

                            <Box bg="rgba(0, 185, 81, .08)" w="100%" overflowX={["scroll", "hidden"]} borderRadius="10px" border="1px solid rgb(0, 185, 81)"><Flex w={["700px", "100%" ]}pb={["3", "8"]} pt={["7", "12"]} px="5" borderRadius="10px" direction="column">
                                <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="500" borderBottom="1px solid rgb(200,200,220)">
                                    <Flex w='10%'>S/N.</Flex>
                                    <Flex w='18%'>Amount</Flex>
                                    <Flex w='18%'>Bank Name</Flex>
                                    <Flex w="18%">Account Number</Flex>
                                    <Flex w="18%">Account Routing</Flex>
                                    <Flex w="18%">Status</Flex>
                                </Flex>
                                {
                                    Array.isArray(withdrawData[0]) &&
                                    withdrawData[0]?.map((item, index) => (  
                                    <Flex align="center" w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="500" borderBottom="1px solid rgb(200,200,220)" key={index}>
                                        <Flex w='10%'>{index +1}.</Flex>
                                        <Flex w='18%'>{"$"+parseInt(item.amount)?.toLocaleString()+".00"}</Flex>
                                        <Flex w='18%'>{item.bankname}</Flex>
                                        <Flex w="18%">{item.bankacc}</Flex>
                                        <Flex w="18%">{item.routingNo}</Flex>
                                        <Flex w="18%"><Flex borderRadius="5px" fontWeight="bold" px="4" py="1" color="#fff" bg={item.status === "1" ? "#00b951" : "#faa628"}>{item.status === "1" ? "Paid" : "Pending"}</Flex></Flex>
                                    </Flex>
                                    ))
                                }
                            </Flex></Box>
                            <Text pb={["100px", "200px"]}></Text>
                        </>
                        :
                        screen === 9 ?
                        <>
                            <Flex align="center"><Image src={cryp} mr="5" w="60px" /> <Text fontSize={["24px", "30px" ]} fontWeight="500">Deposit</Text></Flex>

                            <Text w="100%" h="1px" mt={["4", "6"]} mb="6" bg="rgb(200,200,220)"></Text>

                            <Text fontSize="20px" fontWeight="500">Create a Deposit Request</Text>
                            <Text color="#494949" mt="1" fontSize={["13px", "15px"]}>Kindly fill the form provided below</Text>
                            <Text w="100%" h="1px" mt={["8", "6"]} bg="rgb(200,200,220)"></Text>
                            
                            {
                                !deposit ?
                                <>
                                    <Flex w="100%" direction="column" mt={["8", "12"]}>
                                        <Flex w="100%" align="center" direction={["column", "row"]} justify="space-between">
                                            <Flex w={["100%", "47%"]} direction="column">
                                                <Text fontWeight="600" fontSize="13px" mb="1">Coin Type</Text>
                                                <Text w="100%"><select style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px", cursor: "pointer"}} ref={ref1}>
                                                    <option value="" selected disabled>Choose Coin</option>
                                                    <option>BTC</option>
                                                    <option>USDT (ERC 20)</option>
                                                    <option>USDT (TRC 20)</option>
                                                    <option>DOGECOIN</option>
                                                </select></Text>
                                            </Flex>

                                            <Flex mt={["4", "0"]} w={["100%", "47%"]} direction="column">
                                                <Text fontWeight="600" fontSize="13px" mb="1">Amount</Text>
                                                <Text w="100%"><input type="number" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the amount in $" ref={ref2} /></Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex w="100%" mt="4" justify="flex-end"><Flex w={["100%", "auto"]} padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none" onClick={() => iniDeposit()}> 
                                        <Flex align="center">PROCEED <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                                    </Flex></Flex>
                                </>
                                :
                                <>
                                    <Flex w="100%" align="center" mt={["8", "10"]}>
                                        <Flex mr="5" w="60px" h="60px" borderRadius="100%" border="1px solid #6b7280" justify="center" align="center"><Image w="30px" src={cryp} /></Flex>
                                        <Flex flex="1" fontSize="30px" fontWeight="700">${parseInt(send2)?.toLocaleString()}</Flex>
                                    </Flex>
                                    <Text fontWeight="600" fontSize="16px" mt="7">{send1} ADDRESS</Text>
                                    <Flex align="center" mt="2" direction={["column", "row"]}>
                                        <Text textDecoration="underline" wordBreak="break-all">{send1 === "BTC" ? "bc1qm4jmgvdht6ews9vtgklqezq5n6q6x8kzasld32" : send1 === "USDT (TRC 20)" ? "TWFqnVow8bAUCvbEwim8DS4gxLxrwytwJa" : send1 === "DOGECOIN" ? "DGAmEcZSjtW3To8ozbtfZ8ZQc9iDcoiYiF" : "0x65eAD26E9b15aa56CB7DfA5E0393Ded045B13FF2"}</Text>

                                        <Flex ml={["auto", "5"]} cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="8px 15px" justify="center" align="center" fontSize="13px" onClick={() => {
                                            navigator.clipboard.writeText(send1 === "BTC" ? "bc1qm4jmgvdht6ews9vtgklqezq5n6q6x8kzasld32" : send1 === "USDT (TRC 20)" ? "TWFqnVow8bAUCvbEwim8DS4gxLxrwytwJa" : send1 === "DOGECOIN" ? "DGAmEcZSjtW3To8ozbtfZ8ZQc9iDcoiYiF" : "0x65eAD26E9b15aa56CB7DfA5E0393Ded045B13FF2")
                                            Toast("Wallet address copied to clipboard!", "success")
                                        }}>Copy Address <Text ml="2"><i className="mdi mdi-content-copy"></i></Text></Flex>
                                    </Flex>

                                    <Flex w="100%" direction="column" mt="5">
                                        {
                                            ["Send Exactly $"+parseInt(send2)?.toLocaleString()+" "+send1+" to this address", "Ensure to contact the live support after you make a deposit", "Proceed to provide your transaction/payment proof or details to the live support for verifications", "Your account will be credited after verification."].map((item, index) => (
                                                <Flex align="center" key={index} mb="1" color="#6b7280" fontSize="14px">
                                                    <Text mr="2" color="#288177" fontSize="20px"><i className="mdi mdi-check-all"></i></Text>
                                                    {item}
                                                </Flex>
                                            ))
                                        }
                                    </Flex>
                                    <Flex mb={["4", "0"]} w='100%' mt="5" align="center">
                                        <Image h="40px" mr="3" src={send1 === "BTC" ? btc : usdt} />
                                        <Flex w="100%"><Flex w={["100%", "auto"]} padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none" onClick={() => {
                                            Toast("Alright "+userData[0]?.fname+", provide your payment proof", "success")
                                            setTimeout(function() {
                                                window.tidioChatApi.open()
                                            }, 1500)
                                            setSend1("")
                                            setSend2("")
                                            setDeposit(false)
                                        }}> 
                                            <Flex align="center">VERIFY PAYMENT <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                                        </Flex></Flex>
                                    </Flex>
                                </>
                            }
                            <Text pb={["100px", "200px"]}></Text>
                        </>
                        :
                        <> 
                            <Text fontSize={["24px", "30px" ]} fontWeight="500">Account Settings</Text>
                            <Text mb="8" color="#494949" mt="2" fontSize={["13px", "15px"]}>Update your account settings</Text>
                            <Flex w="100%" align="center">
                                <Flex backgroundImage={selectedFile !== null ? URL.createObjectURL(selectedFile) : userData[0]?.dp !== "" ? userData[0]?.dp : user1} w="130px" h="130px" borderRadius="100%" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center"></Flex>

                                <Flex ml={["3", "5"]} direction="column" flex="1">

                                    <Flex mb="3"><Flex as="button" padding={["8px 20px", "8px 35px"]} cursor="pointer" borderRadius="5px" color="#001e0e" bg="#fff" border="1px solid #001e0e" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontSize={["13px", '15px']} onClick={() => document.getElementById('dp').click()}>SELECT PHOTO <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex></Flex>
                                    
                                    
                                    <form style={{ width: "100%" }} onSubmit={(e) => uploadPhoto(e)}>
                                        <Flex w="0" h="0" overflow="hidden" direction="column">
                                            <input type="file" style={{ width: "0", opacity: "0" }} id="dp" accept="image/x-png,image/jpeg" onChange={handleFileSelect} />
                                        </Flex>

                                        <Flex fontSize={["13px", '15px']} border="1px solid #001e0e" as="button" padding="8px 35px" cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }}>{load1 ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>SAVE <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></>}</Flex>
                                    </form>
                                </Flex>
                            </Flex>

                            
                            <Text w="100%" h="1px" mt="12" bg="rgb(200,200,220)"></Text>
                            <Flex w="100%" direction={["column", "row"]} justify="space-between">
                                <Flex w={["100%", "45%"]} direction="column" pt={["8", "12"]}>
                                    <Text fontWeight="600" fontSize={["20px", "25px"]} mb="5">Account Information</Text>

                                    <Text textTransform="capitalize" borderBottom="1px solid rgb(200,200,220)" pb="4"><Text as="span" fontWeight="600" mr="3">Fullname: </Text> {userData[0]?.fname + " " + userData[0]?.lname}</Text>
                                    <Text borderBottom="1px solid rgb(200,200,220)" pb="4" mt="5"><Text as="span" fontWeight="600" mr="3">Email: </Text> {userData[0]?.email}</Text>
                                    <Text mt="5" borderBottom="1px solid rgb(200,200,220)" pb="4" textTransform="capitalize"><Text as="span" fontWeight="600" mr="3">Username: </Text> {userData[0]?.uname}</Text>
                                    <Text mt="5" borderBottom="1px solid rgb(200,200,220)" pb="4" textTransform="capitalize"><Text as="span" fontWeight="600" mr="3">Account Created: </Text> {userData[0]?.createddate}</Text>
                                </Flex>

                                <Flex w={["100%", "45%"]} pt="12" pb={["120px", "160px"]} direction="column">
                                    <Text fontWeight="600" fontSize={["20px", "25px"]} mb="5">Change your password</Text>

                                    <form style={{ width: "100%" }} onSubmit={(e) => changePass(e)}>
                                        <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px", border: "2px solid #001e0e" }} placeholder="Old Password" onInput={(e) => setOldPass(e.target.value)} required type="password" /></Text>

                                        <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px", border: "2px solid #001e0e" }} placeholder="New Password" onInput={(e) => setNewPass(e.target.value)} required type="password" /></Text>

                                        <Flex w="100%" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none">
                                            {
                                                load2 ? <Spinner color="#fff" emptyColor="lightgrey" /> : 
                                                <Flex align="center">CHANGE <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                                            }
                                        </Flex>
                                    </form>
                                </Flex>
                            </Flex>
                        </>
                    }
                </Flex>










            </Flex>
        </Flex>
    )
}