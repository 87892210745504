import axios from "axios";
const link = "https://equityflex.in/";

export async function ApiRequest(type, url, data) {
    //AXIOS CLIENT HEADER
    const client = axios.create({
      baseURL: link,
      headers: {
        "Content-Type": "application/json",
      },
    });
    try {
      const response = type === "post" ? await client.post(url, data) : await client.get(url);
      return response?.data;
    }
    catch (error) {
        if (error?.response?.status) {
            return error?.response?.data?.message;
        }
        else {
            return "Cannot connect at the moment, please try again";
        }
    }
}


export async function ApiFile(type, url, data) {
    //AXIOS CLIENT HEADER
    const client = axios.create({
      baseURL: link,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    try {
      const response = type === "post" ? await client.post(url, data) : await client.get(url);
      return response?.data;
    }
    catch (error) {
        if (error?.response?.status) {
            return error?.response?.data?.message;
        }
        else {
            return "Cannot connect at the moment, please try again";
        }
    }
}
