import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
} from "@chakra-ui/react";
import registerImage from "./register.png"
import Toast from './toast';
import { ApiRequest } from './request';
import { useHistory } from 'react-router-dom';



export default function Register({ setSelected }) {
    const history = useHistory()
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [uname, setUname] = useState("")
    const [pass, setPass] = useState("")
    const [occupy, setOccupy] = useState("")
    const [income, setIncome] = useState("")
    const [inRange, setInRange] = useState("")
    const [loading, setLoading] = useState(false)

    
	async function registerFunc(e) {
		e.preventDefault()
        setLoading(true)
        const res = await ApiRequest("post", "register.php", {
            fname: fname,
            lname: lname,
            email: email,
            uname: uname,
            pass: pass,
            occupy: occupy,
            income: income,
            inRange: inRange,
        })
        if(res?.status === true) {
            await ApiRequest("post", "mailme.php", {
                fname: fname,
                email: email,
            })
            Toast(res.message, "success")
            setTimeout(function() {
                setSelected(6)
                history.push("/login")
            }, 1500)
        }
        else {
            Toast(res?.message, "error")
        }
        setLoading(false)
    }

    return (
        <Flex w="100%" direction="column" px={['5', "0"]} align="center" py={["60px", "80px"]} bg="rgb(250,250,250)">
            <Flex w={["100%", "570px"]} direction="column" align="center" data-aos="fade-up">
                <Image src={registerImage} w={["60px", "80px"]} mb="5" />
                <Text mb="1" className="font" fontSize="25px" fontWeight="600" textAlign="center">Create an account</Text>
                <Text w="100%" mb="6" textAlign="center" fontSize={["14px", "15px"]}>Fill in your details below to register a new account</Text>
                
                <form onSubmit={(e) => registerFunc(e)} id="myForm" style={{ width: "100%" }}>
                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="First name" onInput={(e) => setFname(e.target.value)} required /></Text>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Last name" onInput={(e) => setLname(e.target.value)} required /></Text>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Email" onInput={(e) => setEmail(e.target.value)} required /></Text>
                    
                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Username" onInput={(e) => setUname(e.target.value)} required /></Text>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Password" onInput={(e) => setPass(e.target.value)} required type="password" /></Text>

                    <Flex w="100%" align="center" mt="2" mb="6">
                        <Flex h="1px" bg="slategray" flex="1"></Flex>
                        <Text mx="8" color="slategrey">Other details</Text>
                        <Flex h="1px" bg="slategray" flex="1"></Flex>
                    </Flex>
                    
                    <Text w="100%" mb="4">
                        <select style={{ width: "100%" }} onChange={(e) => setOccupy(e.target.value)} required type="text">
                            <option disabled selected value="">Are you occupied?</option>
                            <option>Yes</option>
                            <option>No</option>
                        </select>
                    </Text>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Your source of income?" onInput={(e) => setIncome(e.target.value)} required type="text" /></Text>

                    <Text w="100%" mb="4">
                        <select style={{ width: "100%" }} onChange={(e) => setInRange(e.target.value)} required type="text">
                            <option disabled selected value="">What is your estimated income range?</option>
                            <option>$1,000 - $5,000</option>
                            <option>$5,000 - $10,000</option>
                            <option>$10,000 - $50,000</option>
                            <option>$50,000 - $100,000</option>
                            <option>$100,000 - $500,000</option>
                            <option>$500,000 - $1,000,000</option>
                            <option>$1,000,000 - $5,000,000</option>
                            <option>$5,000,000 - $50,000,000</option>
                            <option>$50,000,000 and above</option>
                        </select>
                    </Text>



                    <Flex w="100%" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none">
                        {
                            loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : 
                            <Flex align="center">REGISTER <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                        }
                    </Flex>
                </form>

                <Text mt="8" textAlign="center">Already have an account? <Text ml="2" as="span" cursor="pointer" color="#00b951" onClick={() => {
                    setSelected(6)
                    history.push("/login")
                }}>Log in Here</Text></Text>
                
            </Flex>
        </Flex>
    )
}