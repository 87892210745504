import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
} from "@chakra-ui/react";
import shieldImage from "./shield.png"
import Toast from './toast';
import { ApiRequest } from './request';
import { useHistory } from 'react-router-dom';



export default function Signin({ setIsLogIn, setSelected }) {
    const history = useHistory()
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [loading, setLoading] = useState(false)

    
	async function loginFunc(e) {
		e.preventDefault()
        setLoading(true)
        const res = await ApiRequest("post", "login.php", {
            email: email,
            pass: pass,
        })
        if(res?.status === true) {
			sessionStorage.setItem("eid", res?.data?.uid)
            setIsLogIn(true)
            setSelected(5)
            history.push("/dashboard")
            Toast(res.message, "success")
        }
        else {
            Toast(res?.message, "error")
        }
        setLoading(false)
    }

    return (
        <Flex w="100%" direction="column" px={['5', "0"]} align="center" py={["100px", "80px"]} bg="rgb(250,250,250)">
            <Flex w={["100%", "570px"]} direction="column" align="center" data-aos="fade-up">
                <Image src={shieldImage} w={["60px", "80px"]} mb="5" />
                <Text mb="1" className="font" fontSize="25px" fontWeight="600" textAlign="center">Login</Text>
                <Text w="100%" mb="6" textAlign="center" fontSize={["14px", "15px"]}>Fill in your details below to login to your account</Text>
                
                <form onSubmit={(e) => loginFunc(e)} id="myForm" style={{ width: "100%" }}>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Email address" onInput={(e) => setEmail(e.target.value)} required /></Text>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Password" onInput={(e) => setPass(e.target.value)} required type="password" /></Text>

                    <Flex mb="5" flex="1" justify="flex-end"><Text cursor="pointer" textDecor="underline" fontSize="14px" onClick={() => history.push("/forgot")}>Forgot Password?</Text></Flex>

                    <Flex w="100%" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none">
                        {
                            loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : 
                            <Flex align="center">LOG IN <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                        }
                    </Flex>
                </form>

                <Text mt="8" textAlign="center">Don't have an account? <Text ml="2" as="span" cursor="pointer" color="#00b951" onClick={() => {
                    setSelected(7)
                    history.push("/register")
                }}>Register Here</Text></Text>
                
            </Flex>
        </Flex>
    )
}