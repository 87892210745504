import { useState, useEffect } from 'react';
import { 
	Flex,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Overview from './overview';
import ScrollToTop from "./sc";
import { ApiRequest } from './request';


export default function Dashboard() {
    const history = useHistory()
    const isLogged = sessionStorage.getItem("eid")
    const [userData, setUserData] = useState("")
    const [loansData, setLoansData] = useState("")
    const [withdrawData, setWithdrawData] = useState("")
    
    useEffect(() => {
        if(isLogged === null) {
            history.push("/")
        }
    }, [])


    async function loadUser() {
        const res = await ApiRequest("post", "loaduser.php", {
            uid: sessionStorage.getItem("eid"),
        })
        if(res?.status === true) {
			setUserData([res?.data])
        }
	}

    async function loadLoans() {
        const res = await ApiRequest("post", "loaduser.php", {
            uid2: sessionStorage.getItem("eid"),
        })
        if(res?.status === true) {
            setLoansData([res?.data])
        }
	}

    async function loadWithdraws() {
        const res = await ApiRequest("post", "loaduser.php", {
            uid3: sessionStorage.getItem("eid"),
        })
        if(res?.status === true) {
            setWithdrawData([res?.data])
        }
	}

    useEffect(() => {
        loadUser()
        loadLoans()
        loadWithdraws()
    }, [])


    return (
        <Flex w="100%" direction="column">
            <Router>				
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/dashboard"><Overview userData={userData} loadUser={loadUser} loansData={loansData} withdrawData={withdrawData} loadWithdraws={loadWithdraws} /></Route>
                        <Route><Overview  userData={userData} loadUser={loadUser} /></Route>
                    </Switch>
                </ScrollToTop>
            </Router>
        </Flex>
    )
}