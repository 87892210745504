import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
    Avatar,
} from "@chakra-ui/react";
import logo from "./logo.png";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


export default function Nav({ isLogIn, setIsLogIn, selected, setSelected }) {
    const history = useHistory()
    const location = useLocation()
    const [bgC, setBgC] = useState("none")
    const menu = ["Invest", "About", "Faqs", "Contact"]
    const [loading, setLoading] = useState(false)
    const [navOpen, setNavOpen] = useState(false)

    const handleNavScroll = () => {
        const position = window.pageYOffset;
        if (position > 15) {
            setBgC("0 3px 5px -1px rgba(20, 20, 20, .08)")
        } else {
            setBgC("none")
        }
	};

	useEffect(() => {
        window.addEventListener('scroll', handleNavScroll);
        return () => {
            window.removeEventListener('scroll', handleNavScroll);
        };
	});

    return (
        <>
        <Flex w="100%" py="2" bg="#262626" display={["none", "flex"]}></Flex>
        <Flex w="100%" position="sticky" top="0" left="0" py={["3.5", "3.5"]} px={["5", "10%"]} align="center" bg="#fff" boxShadow={[location.pathname.includes('dashboard') ? "none" : bgC, bgC]} zIndex="10">
            <Flex flex="1" align="center">
                <Flex align="center" cursor="pointer" onClick={() => {
                    setSelected("64646")
                    history.push("/")
                }}>
                    <Image src={logo} h={["34px", "50px"]} />
                </Flex>
                <Text h="3px" bg="#424242" ml={["3", "6"]} w={["20px", "40px"]} borderRadius="5px"></Text>
                <Flex ml="auto" display={["flex", "none"]} fontSize="20px" onClick={() => setNavOpen(true)}>
                    <i className='mdi mdi-menu'></i>
                </Flex>
            </Flex>

            <Flex w="58%" justify="space-between" align="center" fontSize="15px" display={["none", "flex"]}>
                {
                    menu.map((item, index) => (
                        <Text key={index} cursor="pointer" color={selected === index ? "#00b951" : "#000"} _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="500" onClick={() => {
                            setSelected(index)
                            history.push("/")
                            setTimeout(function() {
                                if(index === 0) {
                                    const element = document.getElementById('investArea')
                                    return window.scrollTo({
                                    top: element ? element.offsetTop + 0 : 0,
                                    behavior: 'smooth',
                                    })
                                }
                                else if(index === 1) {
                                    const element = document.getElementById('aboutArea')
                                    return window.scrollTo({
                                    top: element ? element.offsetTop - 50 : 0,
                                    behavior: 'smooth',
                                    })
                                }
                                else if(index === 2) {
                                    const element = document.getElementById('faqArea')
                                    return window.scrollTo({
                                    top: element ? element.offsetTop -50 : 0,
                                    behavior: 'smooth',
                                    })
                                }
                                else if(index === 3) {
                                    const element = document.getElementById('contactArea')
                                    return window.scrollTo({
                                    top: element ? element.offsetTop - 100 : 0,
                                    behavior: 'smooth',
                                    })
                                }
                            }, 100)
                        }}>{item}</Text>
                    ))
                }

                <Text h="3px" bg="rgb(200,200,220)" w="40px" borderRadius="5px"></Text>
                {
                    isLogIn ? 
                    <>
                        <Flex align="center" onClick={() => {
                                setSelected(5)
                                history.push("/dashboard")
                            }} cursor="pointer">
                            <Avatar size="sm" bg="#00b951"></Avatar>
                            <Text cursor="pointer" _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="500" ml="2" color={selected === 5 ? "#00b951" : "#000"} >Dashboard</Text>
                        </Flex>
                        <Text cursor="pointer" _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="500" onClick={() => {
                            setLoading(true)
                            setTimeout(function() {    
                                sessionStorage.removeItem("eid")
                                setIsLogIn(false)
                                history.push("/")
                                setLoading(false)
                            }, 1500)
                        }}>{loading ? <Spinner color="#000" emptyColor='grey' size="sm" /> : "Sign Out"}</Text>
                    </>
                    :
                    <>
                        <Flex align="center" onClick={() => {
                                setSelected(6)
                                history.push("/login")
                            }} cursor="pointer">
                            <Avatar size="sm" bg="#00b951"></Avatar>
                            <Text cursor="pointer" color={selected === 6 ? "#00b951" : "#000"} _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="500" ml="2">Log In</Text>
                        </Flex>
                        <Text cursor="pointer" color={selected === 7 ? "#00b951" : "#000"} _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="500" onClick={() => {
                            setSelected(7)
                            history.push("/register")
                        }}>Register</Text>
                    </>
                }
            </Flex>
        </Flex>

        {
            navOpen &&
            <Flex w="100%" h="100%" position="fixed" top="0" left="0" direction="column" zIndex="100">
                <Flex w="100%" h="100%" position="fixed" top="0" left="0" direction="column" zIndex="1000" bg="rgba(0,0,0,0.7)" onClick={() => setNavOpen(false)}></Flex>

                <Flex w="80%" data-aos="fade-left" data-aos-duration="500" h="100%" position="fixed" top="0" right="0" direction="column" zIndex="10000" bg="#fff" py="6" px="5" display={["flex", "none"]}>  
                    <Flex mb="8" ml="auto" display={["flex", "none"]} fontSize="24px" onClick={() => setNavOpen(false)}>
                        <i className='mdi mdi-close'></i>
                    </Flex>

                    {
                        isLogIn ? 
                        <>
                            <Flex w="100%" align="center" mb="5" pb="4" borderBottom="1px solid rgb(200,200,220)" onClick={() => {
                                    setNavOpen(false)
                                    setSelected(5)
                                    history.push("/dashboard")
                                }}>
                                <Avatar size="sm" bg="#00b951"></Avatar>
                                <Text cursor="pointer" _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="600" ml="2" color={selected === 5 ? "#00b951" : "#000"} >Dashboard</Text>
                            </Flex>
                            <Text cursor="pointer" _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="600" mb="5" pb="4" borderBottom="1px solid rgb(200,200,220)" onClick={() => {
                                setLoading(true)
                                setTimeout(function() {
                                    setNavOpen(false)
                                    sessionStorage.removeItem("eid")
                                    setIsLogIn(false)
                                    history.push("/")
                                    setLoading(false)
                                }, 1500)
                            }}>{loading ? <Spinner color="#000" emptyColor='grey' size="sm" /> : "Sign Out"}</Text>
                        </>
                        :
                        <>
                            <Flex w="100%" align="center" mb="5" pb="4" borderBottom="1px solid rgb(200,200,220)" onClick={() => {
                                    setNavOpen(false)
                                    setSelected(6)
                                    history.push("/login")
                                }}>
                                <Avatar size="sm" bg="#00b951"></Avatar>
                                <Text cursor="pointer" color={selected === 6 ? "#00b951" : "#000"} _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="600" ml="2">Log In</Text>
                            </Flex>
                            <Text mb="5" pb="4" borderBottom="1px solid rgb(200,200,220)" cursor="pointer" color={selected === 7 ? "#00b951" : "#000"} _hover={{ color: "#00b951" }} transition="200ms ease-in-out" fontWeight="600" onClick={() => {
                                setNavOpen(false)
                                setSelected(7)
                                history.push("/register")
                            }}>Register</Text>
                        </>
                    }

                    {
                        menu.map((item, index) => (
                            <Text key={index} cursor="pointer" color={selected === index ? "#00b951" : "#000"} _hover={{ color: "#00b951" }} mb="5" pb="4" borderBottom="1px solid rgb(200,200,220)" transition="200ms ease-in-out" fontWeight="600" onClick={() => {
                                setSelected(index)
                                history.push("/")
                                setNavOpen(false)
                                setTimeout(function() {
                                    if(index === 0) {
                                        const element = document.getElementById('investArea')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop + 0 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                    else if(index === 1) {
                                        const element = document.getElementById('aboutArea')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop - 50 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                    else if(index === 2) {
                                        const element = document.getElementById('faqArea')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop -50 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                    else if(index === 3) {
                                        const element = document.getElementById('contactArea')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop - 100 : 0,
                                        behavior: 'smooth',
                                        })
                                    }
                                }, 100)
                            }}>{item}</Text>
                        ))
                    }

                    
                </Flex>
            </Flex>
        }
        </>
    )
}