import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
} from "@chakra-ui/react";
import shieldImage from "./shield.png"
import Toast from './toast';
import { ApiRequest, ApiFile } from './request';
import { useHistory, useParams } from 'react-router-dom';



export default function Reset({ setIsLogIn, setSelected }) {
    const {slug} = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [pass, setPass] = useState("")
    const [pass2, setPass2] = useState(false)


    async function addData(e) {
        e.preventDefault()
        var a = pass
        var b = pass2
        if(a !== "" && b !== "" && a === b) {
            setLoading(true)
            const formData = new FormData()
            formData.append("uid16", slug)
            formData.append("pass", a)
            const res = await ApiFile("post", "upload.php", formData)
            if(res?.status === true) {
                Toast(res?.message, "success")
                history.push("/login")
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else if(a !== b) {
            Toast("Passwords don't match kindly verify", "error")
        }
        else {
            Toast("Kindly enter your email", "error")
        }
	}
    
	

    return (
        <Flex w="100%" direction="column" px={['5', "0"]} align="center" py={["100px", "80px"]} bg="rgb(250,250,250)">
            <Flex w={["100%", "570px"]} direction="column" align="center" data-aos="fade-up">
                <Image src={shieldImage} w={["60px", "80px"]} mb="5" />
                <Text mb="1" className="font" fontSize="25px" fontWeight="600" textAlign="center">Reset Password</Text>
                <Text w="100%" mb="6" textAlign="center" fontSize={["14px", "15px"]}>Fill in your new password</Text>
                
                <form onSubmit={(e) => addData(e)} id="myForm" style={{ width: "100%" }}>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="New Password" onInput={(e) => setPass(e.target.value)} required type="password" /></Text>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Retype New Password" onInput={(e) => setPass2(e.target.value)} required type="password" /></Text>

                    <Flex w="100%" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none">
                        {
                            loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : 
                            <Flex align="center">RESET PASSWORD <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                        }
                    </Flex>
                </form>
                
            </Flex>
        </Flex>
    )
}