import { useState, useEffect } from 'react';
import { 
	Flex,
	Image,
	Text,
	Spinner,
} from "@chakra-ui/react";
import shieldImage from "./shield.png"
import Toast from './toast';
import { ApiRequest } from './request';
import { useHistory } from 'react-router-dom';
import { ApiFile } from './request';



export default function Forgot({ setIsLogIn, setSelected }) {
    const history = useHistory()
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)


    async function addData(e) {
        e.preventDefault()
        var a = email
        if(a !== "") {
            setLoading(true)
            const formData = new FormData()
            formData.append("uid15", a)
            const res = await ApiFile("post", "upload.php", formData)
            if(res?.status === true) {
                await ApiRequest("post", "mailforgot.php", {
                    email: a,
                    link: res.data.link,
                })
                Toast(res?.message, "success")
                history.push("/login")
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Kindly enter your email", "error")
        }
	}

    
	

    return (
        <Flex w="100%" direction="column" px={['5', "0"]} align="center" py={["100px", "80px"]} bg="rgb(250,250,250)">
            <Flex w={["100%", "570px"]} direction="column" align="center" data-aos="fade-up">
                <Image src={shieldImage} w={["60px", "80px"]} mb="5" />
                <Text mb="1" className="font" fontSize="25px" fontWeight="600" textAlign="center">Forgot Password</Text>
                <Text w="100%" mb="6" textAlign="center" fontSize={["14px", "15px"]}>Fill in your email below to recover your account</Text>
                
                <form onSubmit={(e) => addData(e)} id="myForm" style={{ width: "100%" }}>

                    <Text w="100%" mb="4"><input style={{ width: "100%", padding: "17px 35px" }} placeholder="Email address" onInput={(e) => setEmail(e.target.value)} required /></Text>

                    <Flex w="100%" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="5px" bg="#001e0e" color="#fff" fontWeight="500" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} as="button" type="submit" border="none">
                        {
                            loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : 
                            <Flex align="center">RECOVER <i className='mdi mdi-arrow-right' style={{ fontSize: "20px", marginLeft: "8px" }}></i></Flex>
                        }
                    </Flex>
                </form>
                
            </Flex>
        </Flex>
    )
}